import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { removeLoading, setViewportMeta } from './utils/dom';
import { TranslationContextProvider } from './context/TranslationContext';
import { PaymentContextProvider } from './context/PaymentContext';
import { StatusConfigContextProvider } from 'src/context/StatusConfigContext';
import { StyleContextProvider } from './context/StyleContext';
import { FieldsContextProvider } from './context/FieldsContext';
import { ThreeDsContextProvider } from './context/ThreeDsContext';
import { VisitorContextProvider } from './context/VisitorContext';
import { lazyLoad } from './utils/loading';
import { ALLOW_CLIENT_ZOOMING } from './constants/environment';
import './styles/globals.scss';

const HomePage = lazyLoad(async () =>
  import('src/components/pages/HomePage').then(module => ({ default: module.HomePage })),
);

const DemoPage = lazyLoad(async () =>
  import('src/components/pages/DemoPage').then(module => ({ default: module.DemoPage })),
);

const PaymentPage = lazyLoad(async () =>
  import('src/components/pages/PaymentPage').then(module => ({ default: module.PaymentPage })),
);

const FullPage = lazyLoad(async () =>
  import('src/components/pages/FullPage').then(module => ({ default: module.FullPage })),
);

const IframePage = lazyLoad(async () =>
  import('src/components/pages/IframePage').then(module => ({ default: module.IframePage })),
);

const InvoicePage = lazyLoad(async () =>
  import('src/components/pages/InvoicePage').then(module => ({ default: module.InvoicePage })),
);

const SpinnerPage = lazyLoad(async () =>
  import('src/components/pages/SpinnerPage').then(module => ({ default: module.SpinnerPage })),
);

const NotFound = lazyLoad(async () =>
  import('src/components/errors/NotFound').then(module => ({ default: module.NotFound })),
);

const InternalError = lazyLoad(async () =>
  import('src/components/errors/InternalError').then(module => ({ default: module.InternalError })),
);


const App = () => {

  React.useEffect(() => {
    setViewportMeta(ALLOW_CLIENT_ZOOMING);
    removeLoading();
  }, []);

  const renderRoutes = () => (
    <Routes>
      <Route
        path="/"
        element={<HomePage />}
      />
      <Route
        path="/demo"
        element={<DemoPage />}
      />
      <Route
        path="p/:pid"
        element={<PaymentPage />}
      />
      <Route
        path="p/:pid/fullpage"
        element={<FullPage />}
      />
      <Route
        path="p/:pid/iframe"
        element={<IframePage />}
      />
      <Route
        path="p/:pid/unavailable"
        element={<PaymentPage />}
      />
      <Route
        path="client/:ptype/:pid"
        element={<InvoicePage />}
      />
      <Route
        path="client/:ptype/:pid/progress"
        element={<SpinnerPage />}
      />
      <Route
        path="client/:ptype/:pid/payment/details_request/"
        element={<InvoicePage />}
      />
      <Route
        path="errors/404"
        element={<NotFound />}
      />
      <Route
        path="errors/500"
        element={<InternalError />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );

  return (
    <VisitorContextProvider>
      <TranslationContextProvider>
        <PaymentContextProvider>
          <StatusConfigContextProvider>
            <StyleContextProvider>
              <FieldsContextProvider>
                <ThreeDsContextProvider>
                  {renderRoutes()}
                </ThreeDsContextProvider>
              </FieldsContextProvider>
            </StyleContextProvider>
          </StatusConfigContextProvider>
        </PaymentContextProvider>
      </TranslationContextProvider>
    </VisitorContextProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default App;
