export const removeLoading = () => {
  const body = document.body;
  if (body) {
    body.classList.remove('loading');
  }

  document.title = '';

  const spinner = document.getElementById('spinner');
  spinner?.remove();
};

export const setViewportMeta = (allowZooming: boolean): void => {
  const metaTag = document.createElement('meta');
  metaTag.name = 'viewport';

  if (allowZooming) {
    metaTag.content = 'width=device-width, initial-scale=1';
  } else {
    metaTag.content = 'width=device-width, user-scalable=no';
  }

  const existingMetaTag = document.querySelector('meta[name="viewport"]');
  if (existingMetaTag) {
    existingMetaTag.parentNode?.removeChild(existingMetaTag);
  }

  document.head.appendChild(metaTag);
};
