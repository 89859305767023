import { BRAND_NAME } from 'src/constants/environment';
import { DECTA } from 'src/constants/whitelabels';

export const DEMO_CARD_PANS = ['4242424242424242', '4141414141414141'];
export const DEMO_SUCCESS_SCS = '111';
export const DEMO_SUCCESS_PAYMENT_ID = 'c1c8ec372dd2bd141d490f6b807091195dadd9143f86ae982866d75ce2241d5' +
  '7upJeZkgIvBzMmjWdgdASDtRRyAocJFFqbEYoqqFgaLMNkWlqOaSXxrnZPHfWugpo';
export const DEMO_FAILURE_PAYMENT_ID = 'c1c8ec372dd2bd141d490f6b512091195dadd9143f86ae982866d75ce2241d5' +
  '7upJeZkgIvBzMmjWdgdASDtRRyAocJFFqbEYoqqFgaLMNkWlqOaSXxrnZPHfWugpo';
export const DEMO_AMOUNT = '10.00';
export const DEMO_BRAND = 'Demo brand';
export const DEMO_ID = '1e118408-a091-4b4e-96bf-e60e1e0e2ff0';

const DEMO_CURRENCIES: { [key: string]: string } = {
  [DECTA]: 'EUR',
};

export const DEMO_CURRENCY = DEMO_CURRENCIES[BRAND_NAME] || DEMO_CURRENCIES[DECTA];

export const isDemoCardId = (data: string) => DEMO_CARD_PANS.includes(data);
