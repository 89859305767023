type StringType = string | void | undefined;
const assumeString = (data: StringType, defaultVal: StringType): string => data || defaultVal || '';

const assumeBoolean = (data: StringType, defaultVal: boolean): boolean => {
  if ((data || '') === 'true') {
    return true;
  }

  if ((data || '') === 'false') {
    return false;
  }

  return defaultVal;
};

export const PUBLIC_URL = assumeString(process.env.PUBLIC_URL);
export const GATE_API_HOST = assumeString(process.env.REACT_APP_GATE_API_HOST);
export const BRAND_NAME = assumeString(process.env.REACT_APP_BRAND);
export const BRAND_NAME_RAW = assumeString(process.env.REACT_APP_BRAND_RAW);

export const REGISTRATION_NUMBER_NAME = assumeString(process.env.REGISTRATION_NUMBER_NAME);
export const VAT_NUMBER_NAME = assumeString(process.env.VAT_NUMBER_NAME);

export const SENTRY_DSN = assumeString(process.env.REACT_APP_SENTRY_DSN);
export const SENTRY_RELEASE = assumeString(process.env.REACT_APP_SENTRY_RELEASE);
export const SENTRY_ENVIRONMENT = assumeString(process.env.REACT_APP_SENTRY_ENVIRONMENT);

export const ALLOW_CLIENT_ZOOMING = assumeBoolean(process.env.REACT_APP_ALLOW_CLIENT_ZOOMING, true);
